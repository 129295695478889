import { Navigation } from "./navigation";
import { Provider } from "react-redux";
import { store, persistor } from "./redux";
import { ApolloProvider } from "@apollo/client";
import { apolloClient } from "./api/apollo";
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "src/theme";

import { PersistGate } from "redux-persist/integration/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { initSentry } from "./utils/sentry";

const queryClient = new QueryClient();

initSentry();

const App = () => (
    <ChakraProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
            <ApolloProvider client={apolloClient}>
                <Provider store={store}>
                    <PersistGate persistor={persistor}>
                        <Navigation />
                    </PersistGate>
                </Provider>
            </ApolloProvider>
        </QueryClientProvider>
    </ChakraProvider>
);

export default App;
